import { InfoContent, InfoItemContainer, InfoLabel } from './info-item.styles'

interface Props {
    label: string
    content?: any
    contentList?: string[]
    big?: boolean
    noMargin?: boolean
}

const InfoItem: React.FC<Props> = ({ label, content, contentList, big, noMargin, children }) => {
    return (
        <InfoItemContainer noMargin={noMargin}>
            <InfoLabel>{label}</InfoLabel>
            {content ? <InfoContent big={big}>{content}</InfoContent> : null}
            {contentList && (
                <InfoContent>
                    {contentList.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}
                </InfoContent>
            )}
            {children}
        </InfoItemContainer>
    )
}

export default InfoItem
