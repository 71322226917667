import { useState, useRef, useMemo, useEffect, useContext, useCallback, useLayoutEffect } from 'react'
import { Marker, Popup } from 'react-mapbox-gl'
import { RouteComponentProps } from 'react-router-dom'

import { differenceInHours } from 'date-fns'
import { getBounds } from 'geolib'
import { ModalOrderCancel, ModalOrderCancelRef } from 'modals/modal-order-cancel/modal-order-cancel'
import moment from 'moment'

import { LoadingMoreButton } from 'components/_common'
import { AccessComponent } from 'components/access-component/access-component'
import ButtonForm from 'components/button-form'
import CheckboxInput from 'components/checkbox-input'
import { MirrorMap, GlobalMapContext } from 'components/global-map'
import { MapLineTrail } from 'components/map-line'
import { AgentPopup, DraggableMarker, OrderMarker } from 'components/mapbox-view'
import ModalConfirmation, { ModalConfirmationProps, ModalConfirmationRef } from 'components/modal-confirmation'
import ModalDeliveryForecast, { ModalDeliveryForecastRef } from 'components/modal-delivery-forecast'
import ModalLoading from 'components/modal-loading'
import ModalMessage, { ModalMessageProps, ModalMessageRef } from 'components/modal-message'
import OrderDetailHistory from 'components/order-detail-history/order-detail-history'
import OrderDetailInfo from 'components/order-detail-info/order-detail-info'
import OrderDetailTopRow from 'components/order-detail-top-row/order-detail-top-row'
import Spinner from 'components/spinner/spinner'

import { AgentMarker } from 'containers/agent-marker/agent-marker'

import { useUI } from 'contexts'
import { getStatusOrder, getTimeDiff } from 'helpers'
import { useAuth } from 'hooks'
import { useEchoConnection } from 'hooks/use-echo-connection'
import api from 'services/api'
import { Biker, Order, PagedList } from 'types'
import { IOrderHistoric } from 'types/historic'
import { TrailData } from 'types/map-line'

import './style.scss'
import OrderEditingMode from './components/order-editing-mode'
import { MapActionsContainer } from './order-details.styled'
import { TabsContainer, TabItem } from './order-detais.styled'

type Params = {
    order_id: string
}
type PaginationFilters = {
    has_more: boolean
    page: number
    type: string
}

const OrderDetails: React.FC<RouteComponentProps<Params>> = ({ history, match: { params } }) => {
    const { basePath, mall, user } = useAuth()
    const { setErrorModal, setSuccessModal } = useUI()

    const modalOrderCancelRef = useRef<ModalOrderCancelRef>(null)
    const modalConfirmationRef = useRef<ModalConfirmationRef>(null)
    const modalDeliveryForecastRef = useRef<ModalDeliveryForecastRef>(null)
    const modalMessageRef = useRef<ModalMessageRef>(null)

    const [modalMessage, setModalMessage] = useState<ModalMessageProps>({
        title: '',
        message: '',
        onClose: () => null,
    })
    const [modalConfirmation, setModalConfirmation] = useState<ModalConfirmationProps>({
        title: '',
        message: '',
        onYes: () => null,
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [order, setOrder] = useState<Order>()
    const [orderLocation, setOrderLocation] = useState<{ lat: number | undefined; lng: number | undefined }>({
        lat: undefined,
        lng: undefined,
    })
    const [agentInRouteInfo, setAgentInRouteInfo] = useState({} as Biker)
    const [agentPopupVisible, setAgentPopupVisible] = useState<boolean>(false)
    const [trails, setTrails] = useState<TrailData[] | null>(null)
    const [showRouteLines, setShowRouteLines] = useState<boolean>(false)
    const [trailLoading, setTrailLoading] = useState<boolean>(false)
    const [paginationFilter, setPaginationFilter] = useState<PaginationFilters>({
        has_more: false,
        page: 1,
        type: 'log',
    })

    type TabType = 'details' | 'edit' | 'historic'

    const [tab, setTab] = useState<TabType>('details')
    const [historic, setHistoric] = useState<IOrderHistoric[]>()

    const { setMapBoxContentComponent, setFitBounds } = useContext(GlobalMapContext)

    const _getOrder = useCallback(async () => {
        setLoading(true)
        try {
            const { data: order } = await api.get<Order>(`/painel/dm-order/${params.order_id}`)

            const { biker, start_production, birth, end_production, start_delivery, end_delivery } = order

            if (!order.end_delivery) setAgentInRouteInfo(biker)

            setOrder({
                ...order,
                performance: user.isShopkeeper
                    ? null
                    : {
                          production_time: _getDateDiffInTime(start_production || birth, end_production),
                          collect_time: _getDateDiffInTime(end_production, start_delivery),
                          route_time: _getDateDiffInTime(start_delivery, end_delivery),
                          delivery_time: _getDateDiffInTime(end_production, end_delivery),
                          total_time: _getDateDiffInTime(start_production || birth, end_delivery),
                      },
                total_time: getTimeDiff(order.birth || order.created_at),
                time_ready: getTimeDiff(order.updated_at),
            })

            setOrderLocation({ lat: order?.address?.lat || 0, lng: order.address?.lng || 0 })
        } catch (error) {
            setErrorModal({
                title: 'Error',
                subtitle: 'Não foi possível carregar os dados do pedido. Tente novamente mais tarde.',
            })
        }
        setLoading(false)
    }, [params.order_id, user.isShopkeeper])

    const _getTrails = useCallback(async () => {
        setTrailLoading(true)
        try {
            const { data } = await api.get(`/painel/order/${params.order_id}/trail`)
            const { lastOrderTrail, returnedTrail } = _getFormattedTrail(data?.features)
            setTrails([lastOrderTrail, returnedTrail])
        } catch (error) {
            setErrorModal({
                title: 'Error',
                subtitle: 'Não foi possível carregar os dados da rota. Tente novamente mais tarde.',
            })
        }
        setTrailLoading(false)
    }, [order, params.order_id])

    const _toggleShowRoute = useCallback(async () => {
        setShowRouteLines(state => !state)
        if (trails == null) {
            await _getTrails()
        }
    }, [trails, _getTrails])

    const _getFormattedTrail = useCallback(
        (
            trailFeatures
        ): {
            lastOrderTrail: TrailData
            returnedTrail: TrailData
        } => {
            const endDelivery = new Date(order?.end_delivery)
            const trail = {
                color: '#FF9933',
                coordinates: [],
                properties: {
                    order,
                    label: '1/1',
                },
            }
            const coordinateData = item => ({
                value: item.geometry.coordinates,
                props: item.properties,
            })

            const lastOrderTrail = trailFeatures
                .filter(item => new Date(item.properties.created_at) < endDelivery)
                .map(item => {
                    return coordinateData(item)
                })

            const returnedTrail = trailFeatures
                .filter(item => new Date(item.properties.created_at) > endDelivery)
                .map(item => {
                    return coordinateData(item)
                })

            return {
                lastOrderTrail: { ...trail, coordinates: lastOrderTrail },
                returnedTrail: {
                    ...trail,
                    color: 'gray',
                    coordinates: returnedTrail,
                    properties: { ...trail.properties, label: 'Retorno' },
                },
            }
        },
        [order]
    )

    const _changeOrderStatus = useCallback(
        async (status: string, reason?: string) => {
            try {
                setLoading(true)

                switch (Number(status)) {
                    case 0:
                        await api.put(`/painel/dm-order/${order?.id}/cancel`, { reason })
                        break
                    case 3:
                        await api.put(`/painel/dm-order/${order?.id}/in_production`)
                        break
                    case 4:
                        await api.put(`/painel/dm-order/${order?.id}/ready_to_delivery`)
                        break
                    default:
                        await api.put(`/painel/dm-order/${order?.id}`, { status })
                        break
                }

                _getOrder()
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        },
        [order]
    )

    const _deleteOrder = useCallback(async () => {
        try {
            setLoading(true)

            await api.delete(`/painel/dm-order/${order?.id}`)
            history.replace(`/${mall.slug}/rotas/visao-geral`)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }, [order, mall, history])

    const _getDateDiffInTime = useCallback((startDate: string, finalDate: string) => {
        if (!startDate || !finalDate) {
            return null
        }

        const totalHours = differenceInHours(finalDate, startDate)
        const { minutes, seconds } = moment(finalDate).preciseDiff(moment(startDate), true)

        const timeDiff = {
            hours: totalHours < 10 ? `0${totalHours}` : totalHours.toString(),
            minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
            seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
        }

        return `${timeDiff.hours}:${timeDiff.minutes}:${timeDiff.seconds}`
    }, [])

    const _getFitBounds = useCallback(() => {
        if (
            agentInRouteInfo?.location?.lat &&
            agentInRouteInfo?.location?.lng &&
            orderLocation?.lat &&
            orderLocation?.lng
        ) {
            const { minLat, minLng, maxLat, maxLng } = getBounds([
                { latitude: mall.address.lat, longitude: mall.address.lng },
                { latitude: agentInRouteInfo?.location.lat, longitude: agentInRouteInfo?.location.lng },
                { latitude: orderLocation.lat, longitude: orderLocation.lng },
            ])

            return [
                [minLng, minLat],
                [maxLng, maxLat],
            ]
        }

        return orderLocation?.lat && orderLocation?.lng
            ? [
                  [mall.address.lng, mall.address.lat],
                  [orderLocation.lng, orderLocation.lat],
              ]
            : null
    }, [agentInRouteInfo, mall, orderLocation])

    const { agentLocation } = useAgentsFetch({ orderId: order?.id })

    const _getPaginatedHistoric = useCallback(
        async (order: Order) => {
            setLoading(true)
            try {
                const { data } = await api.get<PagedList<IOrderHistoric>>(`/painel/dm-order/${order.id}/logs`, {
                    params: {
                        type: [paginationFilter.type],
                        current_page: paginationFilter.page,
                    },
                })
                setHistoric(!historic ? data.items : [...historic, ...data.items])
                setPaginationFilter(state => ({
                    ...state,
                    has_more: data.has_more,
                    page: state.page + 1,
                }))
            } catch (error) {
                console.log('error', error)
            }
            setLoading(false)
        },
        [historic, paginationFilter]
    )

    const _setTab = useCallback(
        (tab: TabType) => {
            return () => {
                if (tab === 'historic') {
                    _getPaginatedHistoric(order)
                }

                if (tab === 'edit') {
                    setTimeout(
                        () =>
                            setOrderLocation({
                                lat: order.address.lat,
                                lng: order.address.lng,
                            }),
                        500
                    )
                }
                setTab(tab)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [order, historic]
    )

    const _renderMapContent = useCallback(() => {
        setFitBounds(_getFitBounds())
        return (
            <>
                {tab === 'edit' ? (
                    <DraggableMarker
                        coordinates={orderLocation?.lat && orderLocation.lng && [orderLocation.lng, orderLocation.lat]}
                        onDragEnd={({ lngLat: { lat, lng } }: { lngLat: { lat: number; lng: number } }) =>
                            setOrderLocation({ lat, lng })
                        }
                    />
                ) : (
                    <OrderMarker order={order} configs={mall.configs} />
                )}
                <AccessComponent roles={['owner', 'staff']} type="hide">
                    <>
                        {agentInRouteInfo?.id && (
                            <Marker
                                style={{ cursor: 'pointer' }}
                                anchor="bottom"
                                coordinates={[
                                    agentInRouteInfo?.location?.lng || 0,
                                    agentInRouteInfo?.location?.lat || 0,
                                ]}
                                onClick={() => {
                                    setAgentPopupVisible(!agentPopupVisible)
                                }}
                            >
                                <AgentMarker src={agentInRouteInfo.avatar} alt={`agent-${agentInRouteInfo?.id}`} />
                            </Marker>
                        )}
                        {agentPopupVisible && (
                            <Popup
                                coordinates={[
                                    agentInRouteInfo?.location?.lng || 0,
                                    agentInRouteInfo?.location?.lat || 0,
                                ]}
                                offset={[0, -38]}
                            >
                                <AgentPopup agent={agentInRouteInfo} onClosePopup={() => setAgentPopupVisible(null)} />
                            </Popup>
                        )}
                        {showRouteLines && <MapLineTrail trails={trails} />}
                        <MapActionsContainer>
                            <CheckboxInput
                                label={`Visualizar Rota`}
                                checked={showRouteLines}
                                onChange={_toggleShowRoute}
                            />
                        </MapActionsContainer>
                    </>
                </AccessComponent>
            </>
        )
    }, [
        mall,
        agentInRouteInfo,
        orderLocation,
        agentPopupVisible,
        order,
        trails,
        _getFitBounds,
        setFitBounds,
        showRouteLines,
        _toggleShowRoute,
    ])

    const _refresh = useCallback(() => _getOrder(), [_getOrder])

    useEffect(() => {
        _getOrder()
    }, [_getOrder])

    useEffect(() => {
        setPaginationFilter({
            has_more: false,
            page: 1,
            type: 'log',
        })
    }, [])

    useEffect(() => {
        if (order?.biker && agentLocation) {
            setAgentInRouteInfo({ ...order.biker, ...agentLocation })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agentLocation])

    useLayoutEffect(() => {
        setMapBoxContentComponent(_renderMapContent)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_renderMapContent])

    useEffect(() => {
        return () => {
            setMapBoxContentComponent(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="page-container">
            <OrderDetailTopRow
                backClick={() => history.goBack()}
                newRouteClick={() => history.push(`${basePath}/rotas/criar-rota`, { initial: order })}
                followRouteClick={() => console.log('acompanhar')}
                cancelOrderClick={() => {
                    modalOrderCancelRef.current?.show({
                        order,
                        onSelectReason: reason => {
                            _changeOrderStatus('0', reason)
                        },
                    })
                }}
                statusOrder={order?.status}
            />

            <ModalLoading visible={trailLoading} />
            <ModalOrderCancel ref={modalOrderCancelRef} />

            <ModalMessage
                ref={modalMessageRef}
                title={modalMessage.title}
                message={modalMessage.message}
                onClose={modalMessage.onClose}
            />

            <ModalConfirmation
                ref={modalConfirmationRef}
                title={modalConfirmation.title}
                message={modalConfirmation.message}
                onYes={modalConfirmation.onYes}
            />

            <ModalDeliveryForecast ref={modalDeliveryForecastRef} order={order} getOrder={_getOrder} />

            <div className="content-container">
                <div className="order-detail-content-container">
                    <div className="left-column">
                        {loading ? (
                            <div style={{ marginTop: 60, display: 'flex', justifyContent: 'center' }}>
                                <Spinner />
                            </div>
                        ) : (
                            <>
                                <div className="switch-view-mode-container">
                                    <TabsContainer>
                                        <TabItem isActive={tab === 'details'} onClick={_setTab('details')}>
                                            Detalhes
                                        </TabItem>
                                        {Number(order?.status) !== 2 && (
                                            <TabItem isActive={tab === 'historic'} onClick={_setTab('historic')}>
                                                Histórico
                                            </TabItem>
                                        )}
                                        <TabItem isActive={tab === 'edit'} onClick={_setTab('edit')}>
                                            Editar
                                        </TabItem>
                                    </TabsContainer>
                                </div>

                                {tab === 'details' && (
                                    <>
                                        <OrderDetailInfo
                                            deliveryOrigin={order?.delivery_origin}
                                            merchant={order?.merchant}
                                            hasProblem={order?.has_problem}
                                            problems={order?.problems}
                                            route={order?.route}
                                            agent={order?.biker}
                                            orderId={order?.id}
                                            order={order}
                                            attachments={order?.attachments}
                                            collect={order?.collect}
                                            performance={order?.performance}
                                            restaurantName={order?.merchant.name}
                                            orderNumber={order?.reference_id}
                                            orderValue={order?.total_price}
                                            orderChannel={order?.reference_name}
                                            orderPayment={order?.payment?.name}
                                            code={order?.receiver?.code}
                                            orderClient={order?.customer.name}
                                            phoneClient={order?.customer.phone}
                                            localize={order?.customer.localize}
                                            rescheduleClick={() => modalDeliveryForecastRef.current?.openModal()}
                                            deliveryForecast={order?.delivery_forecast}
                                            orderStatus={order?.status}
                                            observation={order?.observation}
                                            problem={order?.problem}
                                            type={order?.type}
                                            orderItems={order?.items || []}
                                            orderAddress={order?.address || {}}
                                            refresh={_refresh}
                                            orderChannelId={order?.sales_channel?.id ?? null}
                                            orderPaymentId={order?.payment.id ?? null}
                                            originId={order?.order_id}
                                        />

                                        <div className="buttons-container">
                                            {!user.isShopkeeper && (
                                                <ButtonForm
                                                    buttonText="Excluir pedido"
                                                    onClick={() => {
                                                        setModalConfirmation({
                                                            title: 'Excluir pedido',
                                                            message: 'Deseja mesmo excluir este pedido?',
                                                            onYes: _deleteOrder,
                                                        })

                                                        modalConfirmationRef.current?.openModal()
                                                    }}
                                                />
                                            )}

                                            {order?.status === '4' && (
                                                <ButtonForm
                                                    secondary
                                                    buttonText="Voltar para produção"
                                                    onClick={() => {
                                                        setModalConfirmation({
                                                            title: 'Mudança de status',
                                                            message: `Deseja alterar este pedido para ${getStatusOrder(
                                                                '3'
                                                            )}?`,
                                                            onYes: () => _changeOrderStatus('3'),
                                                        })

                                                        modalConfirmationRef.current?.openModal()
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </>
                                )}

                                {tab === 'historic' && <OrderDetailHistory historic={historic || []} />}
                                {tab === 'historic' && paginationFilter.has_more && (
                                    <LoadingMoreButton loading={false} onClick={() => _getPaginatedHistoric(order)} />
                                )}

                                {tab === 'edit' && (
                                    <OrderEditingMode
                                        order={order}
                                        getOrder={() => _getOrder()}
                                        orderLocation={orderLocation}
                                        setOrderEditingMode={x => _setTab(x ? 'edit' : 'details')}
                                        setOrderLocation={setOrderLocation}
                                        modalMessageRef={modalMessageRef}
                                        setModalMessage={setModalMessage}
                                    />
                                )}
                            </>
                        )}
                    </div>

                    <div className="right-column">{!!order && <MirrorMap />}</div>
                </div>
            </div>
        </div>
    )
}

type AgentLocation = {
    id: number
    location: {
        lat: number
        lng: number
    }
}
interface useAgentsFetch {
    orderId: number
}
function useAgentsFetch({ orderId }: useAgentsFetch) {
    const [agentLocation, setAgentLocation] = useState<AgentLocation>()
    const socketEvents = useMemo(() => {
        return [
            {
                name: '.location',
                callback: (agent: any) => {
                    setAgentLocation(agent)
                },
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEchoConnection<any, any>({
        enable: !!orderId,
        channelName: orderId ? `order.${orderId}` : null,
        events: socketEvents,
    })

    return { agentLocation }
}

export default OrderDetails
