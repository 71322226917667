import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { format } from 'date-fns'
import { ModalRomaneioRejectRef } from 'modals/modal-romaneio-reject/modal-romaneio-reject'
import { ModalRomaneioRef } from 'modals/modal-romaneio/modal-romaneio'
import { RefreshRomaneioObservable } from 'observables'

import { useUI } from 'contexts'
import { showErrors } from 'helpers'
import { useAuth, useList } from 'hooks'
import { plugApi } from 'services/api'
import { PagedList } from 'types'
import { IRomaneioSimple } from 'types/romaneio-simple'

import { useFetchRomaneios } from './hook/useFetchRomaneios'

function useRomaneioController() {
    const history = useHistory()
    const { store, mall } = useAuth()
    const { setConfirmationModal, setErrorModal } = useUI()

    const { romaneios, dispatchData } = useFetchRomaneios(store.id)

    const ModalRomaneioRef = useRef<ModalRomaneioRef>()
    const modalRomaneioRejectRef = useRef<ModalRomaneioRejectRef>()

    const [amount, setAmount] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(true)

    const groupedRomaneio = useMemo(() => {
        const groupedList: { label: string; date: string; items: IRomaneioSimple[] }[] = []
        setAmount(romaneios?.length)
        romaneios?.forEach(romaneio => {
            const label = format(romaneio.created_at, 'DD/MM/YYYY')
            const date = format(romaneio.created_at, 'YYYY-MM-DD')

            const groupedIndex = groupedList.findIndex(e => e.date === date)
            if (groupedIndex === -1) {
                groupedList.push({ label, date, items: [romaneio] })
            } else {
                groupedList[groupedIndex].items.push(romaneio)
            }
        })

        groupedList.sort((a, b) => {
            const dateA = new Date(a.date)
            const dateB = new Date(b.date)

            return dateB.getTime() - dateA.getTime()
        })

        groupedList.forEach(romaneio => {
            romaneio.items.sort((a, b) => {
                const dateA = new Date(a.created_at)
                const dateB = new Date(b.created_at)

                return dateB.getTime() - dateA.getTime()
            })
        })

        return groupedList
    }, [romaneios])

    const _getRomaneios = useCallback(async () => {
        setLoading(true)
        try {
            const { data } = await plugApi.get<PagedList<IRomaneioSimple>>(
                `/webhooks/?types[]=${store.slug}&status=received`
            )
            dispatchData({ type: 'SET', payload: data.items })
            //setRomaneios(data.items)
        } catch (error) {
            console.log('error', error)
        }
        setLoading(false)
    }, [store, dispatchData])

    const _onClickRomaneio = useCallback(
        (romaneio: IRomaneioSimple) => {
            return () =>
                ModalRomaneioRef.current?.show({
                    romaneio,
                    onChange() {
                        //setRomaneios(romaneios.filter(e => e.id !== romaneio.id))
                        dispatchData({ type: 'REMOVE', payload: romaneio })
                    },
                })
        },
        [dispatchData]
    )

    const _preview = useCallback(
        (romaneio: IRomaneioSimple) => {
            history.push(`/${mall.slug}/${store.slug}/visualizar-romaneio/${romaneio.id}`)
        },
        [history, mall, store]
    )

    const _accept = useCallback(
        (romaneio: IRomaneioSimple) => {
            const callback = async () => {
                setLoading(true)
                try {
                    await plugApi.put<{ message: string; status: boolean }>(`/webhooks/${romaneio.id}`, {
                        status: 'accept',
                        type: 'azul-cargo',
                    })
                    //setRomaneios(romaneios.filter(e => e.id !== romaneio.id))
                    dispatchData({ type: 'REMOVE', payload: romaneio })
                    modalRomaneioRejectRef.current?.close()
                } catch (error) {
                    setErrorModal({
                        title: 'Erro ao aceitar romaneio',
                        subtitle: showErrors(error),
                    })
                }

                setLoading(false)
            }

            setConfirmationModal({
                title: 'Aceitar romaneio',
                subtitle: 'Deseja realmente aceitar este romaneio?',
                type: 'alert',
                modalIcon: 'check-circle',
                rightButtonText: 'Sim, aceitar',
                rightButtonClick: callback,
                leftButtonText: 'Não',
            })
        },
        [dispatchData, setErrorModal, setConfirmationModal]
    )

    useEffect(() => {
        _getRomaneios()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const sub = RefreshRomaneioObservable.subscribe(() => {
            _getRomaneios()
        })

        return () => {
            if (sub) {
                sub.unsubscribe()
            }
        }
    }, [_getRomaneios])

    return {
        amount,
        loading,
        groupedRomaneio,
        ModalRomaneioRef,
        modalRomaneioRejectRef,
        _onClickRomaneio,
        _preview,
        _accept,
    }
}

export { useRomaneioController }
